import { styled } from '@creditas-ui/system'

const Box = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;

  @media (min-width: 801px) {
    display: none;
  }

  > p {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0;
    padding: 0 15px;
  }

  > .box-stores {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    svg,
    > div {
      margin: 0 5px;
      width: 144px;
      height: 43px;
    }

    > div img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`

export { Box }
