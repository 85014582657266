import React from 'react'
import { QueryResult } from '@apollo/react-common'
import { useTranslation } from 'react-i18next'

import { Button } from '@creditas/button'
import { Typography } from '@creditas-ui/typography'

import {
  CircleChartStatus,
  AdvanceOnNativeApp,
  PrimaryButton,
} from './components/'
import { getChartStates, calcPercentage } from './helpers'
import { Card, TitleBox } from './Chart.style'
import { ChartStatus } from '../../services/bff/types'
import { ChartStates } from './enums'

interface Props {
  clientId: number
  onAdvance: Function
  request: (clientId: number) => QueryResult<ChartStatus>
}

const Chart = ({ clientId, onAdvance, request }: Props) => {
  const { data, loading } = request(clientId)
  const CHART_STATE = data?.salaryAdvanceChartStatus.status || ChartStates.Empty
  const {
    canAnticipate,
    continueAnticipate,
    stateTitle,
    stateSubTitle,
    stateValue,
  } = getChartStates(CHART_STATE)

  const { t } = useTranslation()

  const isNotDailyOrMonthLimit = (state: ChartStates) =>
    state !== ChartStates.DailyLimit && state !== ChartStates.MonthlyLimit

  const calculateStatusPercentage = (): number => {
    if (isNotDailyOrMonthLimit(CHART_STATE)) {
      return calcPercentage(
        data?.salaryAdvanceChartStatus.amountAvailableToAdvanceToday,
        data?.salaryAdvanceChartStatus.maxAmountAdvanceableDaily,
      )
    }
    return 0
  }

  const isFromNativeApp = () => !!localStorage.getItem('fromNativeApp')

  function continueSolicitation() {
    window.singleSpaNavigate('/consignado/simulacao-antecipacao-de-salario')
  }

  return (
    <Card>
      <div>
        <TitleBox variant="headingSmMedium" color="neutral.90" component="h1">
          {t('titles.advance')}
        </TitleBox>
        <Typography variant="bodySmLight" color="neutral.90">
          {t(stateTitle)}
        </Typography>
      </div>
      <CircleChartStatus
        title={t(stateSubTitle)}
        subTitle={t('titles.alreadyWithdrawn')}
        total={data?.salaryAdvanceChartStatus[stateValue]}
        alreadyWithdrawn={
          data?.salaryAdvanceChartStatus.amountAdvancedInCurrentCycle
        }
        disabled={!canAnticipate}
        percentage={calculateStatusPercentage()}
      />

      <AdvanceOnNativeApp nativeApp={isFromNativeApp()} />

      <div className={`action ${!isFromNativeApp() ? 'hideOnMobile' : ''}`}>
        {continueAnticipate && (
          <PrimaryButton loading={loading} onClick={continueSolicitation}>
            {t('actions.continueSolicitation')}
          </PrimaryButton>
        )}

        {!continueAnticipate && (
          <PrimaryButton
            loading={loading}
            onClick={onAdvance}
            disabled={!canAnticipate}
          >
            {t('actions.anticipateSalary')}
          </PrimaryButton>
        )}
      </div>
    </Card>
  )
}

export { Chart }
