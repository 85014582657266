import { ChartStates } from '../enums'
import { StateValues } from '../interfaces'

export const CHART_STATES: { [key in ChartStates]: StateValues } = {
  available: {
    canAnticipate: true,
    stateTitle: 'states.available.title',
    stateSubTitle: 'states.available.subTitle',
    stateValue: 'amountAvailableToAdvanceToday',
  },
  pending: {
    canAnticipate: false,
    stateTitle: 'states.pending.title',
    stateSubTitle: 'states.pending.subTitle',
    stateValue: 'amountAvailableToAdvanceToday',
  },
  dailylimit: {
    canAnticipate: false,
    stateTitle: 'states.dailyLimitReached.title',
    stateSubTitle: 'states.dailyLimitReached.subTitle',
    stateValue: 'amountAvailableToAdvanceToday',
  },
  monthlylimit: {
    canAnticipate: false,
    stateTitle: 'states.monthlyLimitReached.title',
    stateSubTitle: 'states.monthlyLimitReached.subTitle',
    stateValue: 'totalAmountAdvanceableInCurrentCycle',
  },
  created: {
    canAnticipate: true,
    continueAnticipate: true,
    stateTitle: 'states.created.title',
    stateSubTitle: 'states.created.subTitle',
    stateValue: 'amountAvailableToAdvanceToday',
  },
  empty: {
    canAnticipate: false,
    stateTitle: 'states.empty.title',
    stateSubTitle: 'states.empty.subTitle',
    stateValue: 'amountAvailableToAdvanceToday',
  },
}

export const getChartStates = (
  state: ChartStates = ChartStates.Empty,
): StateValues => CHART_STATES[state.toLowerCase()] || ''
