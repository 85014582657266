import React from 'react'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import { ThemeProvider, standard } from '@creditas-ui/react'
import tokens from '@creditas/tokens'
import { ApolloProvider } from '@apollo/react-hooks'

import { TranslatedErrorBoundary } from '../../components'
import { ApolloClient } from '../../services/bff/apollo-client'
import { ChartCustomProps } from './index.spa'
import { Chart } from './Chart'
import { useGetChartStatus } from '../../services/bff/queries'
import { setUserContext } from '../../utils'

class App extends React.Component<ChartCustomProps> {
  componentDidCatch() {}

  componentDidMount() {
    const { clientId } = this.props
    setUserContext({ id: clientId.toString() })
  }

  render() {
    const { authToken, clientId, handleAdvance } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <LegacyThemeProvider theme={{ ...tokens }}>
            <ThemeProvider theme={standard}>
              <Chart
                onAdvance={handleAdvance}
                clientId={clientId}
                request={useGetChartStatus}
              />
            </ThemeProvider>
          </LegacyThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
