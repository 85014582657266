import React from 'react'
import { Typography } from '@creditas-ui/typography'

import { AmountHighlight, Box } from './CircleChartStatus.style'
import { formatCurrency } from '../../../../helpers'

enum Colors {
  Primary = 'primary.60',
  Neutral = 'neutral.60',
  NeutralHEX = 'neutral.100',
}

interface Props {
  title: string
  subTitle: string
  disabled: boolean
  total: number
  percentage: number
  alreadyWithdrawn?: number
}

const CircleChartStatus = ({
  title,
  subTitle,
  disabled,
  total = 0,
  alreadyWithdrawn = 0,
  percentage = 0,
}: Props) => {
  const STATUS_COLOR = !disabled ? Colors.Primary : Colors.Neutral
  const MAX_CHART_VALUE = 100
  const canFillCircle = () => percentage !== 0
  const getCorrectStatusColor = () => (!disabled ? 'green' : 'gray')
  return (
    <Box>
      <div className="values">
        <div className="values-box">
          <Typography variant="bodyMdBold" color="neutral.60">
            {title}
          </Typography>
          <AmountHighlight variant="headingLgMedium" color={STATUS_COLOR}>
            {formatCurrency(total)}
          </AmountHighlight>

          <Typography variant="bodyMdBold" color="neutral.60">
            {subTitle}
          </Typography>
          <AmountHighlight variant="bodyXlBold" color={Colors.NeutralHEX}>
            {formatCurrency(alreadyWithdrawn)}
          </AmountHighlight>
        </div>
      </div>
      <svg
        viewBox="0 0 36 36"
        className={`circular-chart ${getCorrectStatusColor()}`}
      >
        <path
          className="circle-bg"
          d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {canFillCircle() && (
          <path
            className="circle"
            stroke-dasharray={`${percentage}, ${MAX_CHART_VALUE}`}
            d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        )}
      </svg>
    </Box>
  )
}

export { CircleChartStatus }
