import React from 'react'

import AppStore from '../../../../components/SvgLike/AppStore'
import GooglePlay from '../../../../components/SvgLike/GooglePlay'
import { Box } from './advanceOnNativeApp.style'

interface Props {
  nativeApp: boolean
}

const AdvanceOnNativeApp = ({ nativeApp }: Props) => {
  return (
    <>
      {!nativeApp && (
        <Box>
          <p>Baixe agora e antecipe seu salário no seu celular</p>
          <div className="box-stores">
            <AppStore />
            <div>
              <GooglePlay />
            </div>
          </div>
        </Box>
      )}
    </>
  )
}

export { AdvanceOnNativeApp }
