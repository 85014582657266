import { styled } from '@creditas-ui/system'
import { selectTheme, Typography } from '@creditas-ui/react'

const Box = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  margin: 30px 0 40px;
  position: relative;

  .values {
    height: 78%;
    width: 78%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .values-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0px 0px 40px 0px rgb(170, 187, 187);
    }
  }

  .circular-chart {
    display: block;
    position: relative;
    z-index: 2;
  }

  .circle-bg {
    fill: none;
    stroke: rgba(235, 235, 235, 0.6);
    stroke-width: 3.5;
  }

  .circle {
    fill: none;
    stroke-width: 3.5;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.green .circle {
    stroke: ${selectTheme('colors.primary.60')};
  }

  .circular-chart.gray .circle {
    stroke: ${selectTheme('colors.neutral.40')};
  }
`

const AmountHighlight = styled(Typography)`
  margin-bottom: 8px;
`

export { AmountHighlight, Box }
